import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'select',
    placeholder: '结算方式',
    value: null,
    prop: 'orderSettlement',
    width: '10%',
    opt:[
      { label: '货到付款', value: 1 },
      { label: '起运地付清', value: 2 },
      { label: '预付X到付X', value: 3 },
      { label: '月结', value: 4 },
      { label: '记账', value: 5 }
    ]
  },
  {
    type: 'input',
    placeholder: '起运地',
    value: '',
    prop: 'startAddress',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '目的地',
    value: '',
    prop: 'endAddress',
    width: '10%'
  },
  {
    type: 'selectTime',
    placeholder: '下单时间起',
    value: null,
    prop: 'startTime',
    width: '10%',
  },
  {
    type: 'selectTime',
    placeholder: '下单时间止',
    value: null,
    prop: 'endTime',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '车牌号',
    value: null,
    prop: 'vinNo',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '业务员',
    value: null,
    prop: 'salesmanId',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '合同号',
    value: null,
    prop: 'orderId',
    width: '13%',
  },
  {
    type: 'input',
    placeholder: '记账对象',
    value: null,
    prop: 'customerName',
    width: '11%',
  },
  {
    type: 'select',
    placeholder: '结算状态',
    value: null,
    prop: 'settlementStatus',
    width: '10%',
    opt:[
      { label: "未结算", value: 0 },
      { label: "部分结算", value: 1 },
      { label: "已结算", value: 2 }
    ],
  },
  {
    type: 'select',
    placeholder: '是否未完成结算',
    value: null,
    prop: 'notSettlement',
    width: '10%',
    opt:[
      { label: "已完结", value: 0 },
      { label: "未完结", value: 1 },
    ],
  },
  {
    type: 'input',
    placeholder: '业务对接人',
    value: null,
    prop: 'businessContactPerson',
    width: '10%',
  },
])
export const columns = ref([
  {
    title: '运输日期',
    dataIndex: 'pushTime',
    align: 'center',
  },
  {
    title: '下单时间',
    dataIndex: 'orderTime',
    align: 'center',
  },
  {
    title: '合同编号',
    dataIndex: 'orderId',
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'vehicleTransStatus.label',
    align: 'center'
  },
  {
    title: '起始地',
    dataIndex: 'startAddress',
    align: 'center'
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    align: 'center'
  },
  {
    title: '车型',
    dataIndex: 'model',
    slots:{ customRender: 'model' },
    align: 'center'
  },
  {
    title: '车牌号',
    dataIndex: 'vinNo',
    align: 'center'
  },
  {
    title: '交车状态',
    dataIndex: 'isDelivery.label',
    align: 'center'
  },
  {
    title: '交车时间',
    dataIndex: 'deliveryTime',
    align: 'center'
  },
  {
    title: '利润月份',
    dataIndex: 'profitMonth',
    align: 'center'
  },
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center'
  },
  {
    title: '录单员',
    dataIndex: 'salesman',
    align: 'center'
  },
  {
    title: '所属机构',
    dataIndex: 'officeOrgName',
    align: 'center'
  },
  // {
  //   title: '部门来源',
  //   dataIndex: 'salesOrg',
  //   align: 'center'
  // },
  {
    title: '客户姓名',
    dataIndex: 'customerName',
    align: 'center'
  },
  {
    title: '业务对接人',
    dataIndex: 'businessContactPerson',
    align: 'center'
  },
  {
    title: '客户类型',
    dataIndex: 'customerType.label',
    align: 'center'
  },
  {
    title: '保险公司',
    dataIndex: 'insuranceCompany.label',
    align: 'center'
  },
  {
    title: '保险费',
    dataIndex: 'insurancePremium',
    align: 'center'
  },
  {
    title: '保险成本',
    dataIndex: 'insuranceCost',
    align: 'center'
  },
  {
    title: '运输费',
    dataIndex: 'freight',
    align: 'center'
  },
  {
    title: '取车费',
    dataIndex: 'placeInFee',
    align: 'center'
  },
  {
    title: '送车费',
    dataIndex: 'takeOutFee',
    align: 'center'
  },
  {
    title: '累计发车价',
    dataIndex: 'transportCost',
    align: 'center'
  },
  {
    title: '合同总金额',
    dataIndex: 'contractAmt',
    align: 'center'
  },
  {
    title: '发票',
    dataIndex: 'isInvoice.label',
    align: 'center'
  },
  {
    title: '结算方式',
    dataIndex: 'orderSettlement.label',
    align: 'center'
  },
  {
    title: '记账对象',
    dataIndex: 'customerName',
    align: 'center'
  },
  {
    title: '车到收',
    dataIndex: 'paidAmt',
    align: 'center'
  },
  {
    title: '税费',
    dataIndex: 'invoiceCost',
    align: 'center'
  },
  {
    title: '返款金额',
    dataIndex: 'refundFee',
    align: 'center'
  },
  {
    title: '返款对象',
    dataIndex: 'refunder',
    align: 'center'
  },
  {
    title: '返款结算状态',
    dataIndex: 'refundSettlementStatus.label',
    align: 'center'
  },
  {
    title: '其他费用',
    dataIndex: 'additionalFeeTotal',
    align: 'center'
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center'
  },
  {
    title: '毛利润',
    dataIndex: 'grossProfit',
    align: 'center'
  },
  {
    title: '已结算金额',
    dataIndex: 'settledAmt',
    align: 'center'
  },
  {
    title: '未结算金额',
    dataIndex: 'settableAmt',
    align: 'center'
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
    slots:{customRender: 'remark'}
  },
])
